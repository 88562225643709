<template>
    <b-modal
      id="procedure-bpa-modal"
      hide-header
      hide-footer
      centered
      size="lg"
      @show="onShow"
      @hidden="closeModal"
    >
      <div class="modal-header">
        <div class="modal-title">
          <span v-if="procedure?.id">Atualizar</span>
          <span v-else>Novo Procedimento</span>
        </div>
        <span class="icon-close">
          <Close class="icon stroke" @click="closeModal" />
        </span>
      </div>
  
      <div class="around-content">
        <b-row class="mt-3">
          <b-col cols="12">
            <p class="form-title mb-3">
              Dados da execução do procedimento
            </p>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Data de atendimento">
              <date-picker
                placeholder="Selecionar"
                id="start_datetime"
                v-model="procedure.attendance_date"
                format="DD/MM/YYYY"
                :clearable="false"
                class="full"
                :readonly="false"
                :state="errors.procedure_attendance_date ? false : null"
              />
              <b-form-invalid-feedback v-if="errors?.procedure_attendance_date">
                {{ errors.procedure_attendance_date }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="9">
            <b-form-group label="Procedimento">
              <ItemHealthPlanSimpleSelector 
                  :clinicHealthPlanId="clinicHealthPlanId"
                  :allowedTypes="['PROCEDURE','APPOINTMENT', 'EXAM', 'RETURN']"
                  v-model="procedure.item"
                  @select="setGuideItem"
                  :state="errors.procedure_item ? false : null"
              />
              <b-form-invalid-feedback v-if="errors?.procedure_item">
                {{ errors.procedure_item }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Qtde.">
              <b-form-input
                v-model="procedure.quantity"
                placeholder="00"
                autocomplete="off"
                type="number"
                min="1"
                :state="errors.procedure_quantity ? false : null"
                ref="procedure_quantity"
              />
              <b-form-invalid-feedback v-if="errors?.procedure_quantity">
                {{ errors.procedure_quantity }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Serviço">
              <b-form-input
                v-model="procedure.service"
                placeholder="000"
                autocomplete="off"
                v-mask="'###'"
                :state="errors.procedure_service ? false : null"
                ref="procedure_service"
              />
              <b-form-invalid-feedback v-if="errors?.procedure_service">
                {{ errors.procedure_service }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Class">
              <b-form-input
                v-model="procedure.class"
                placeholder="000"
                autocomplete="off"
                v-mask="'###'"
                :state="errors.procedure_class ? false : null"
                ref="procedure_class"
              />
              <b-form-invalid-feedback v-if="errors?.procedure_class">
                {{ errors.procedure_class }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Valor Unitário">
              <money 
                id="unitary_value"
                v-model="procedure.unitary_value" 
                class="form-control"
                :state="errors.procedure_unitary_value ? false : null"
                ref="procedure_unitary_value"
              />
              <b-form-invalid-feedback v-if="errors?.procedure_unitary_value">
                {{ errors.procedure_unitary_value }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Valor Total">
              <money 
                id="unitary_value"
                v-model="procedure.total_value" 
                class="form-control"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="CID" :state="errors.procedure_cid ? false : null">
              <multiselect
                v-model="procedure.cid"
                id="cid1"
                label="label"
                track-by="id"
                :options="cid10"
                :option-height="40"
                :showLabels="false"
                :searchable="true"
                :internal-search="false"
                @search-change="searchCid10"
                @select="selectCid1"
                placeholder="Selecionar"
                class="with-border"
                ref="procedure_cid"
              >
            <template slot="caret">
                <div class="chevron">
                  <v-chevron-down />
                </div>
              </template>
              <template slot="noOptions"
                >Digite para pesquisar um CID10
              </template>
              <template slot="noResult">Nenhum CID10 encontrado</template>
            </multiselect>
            <b-form-invalid-feedback v-if="errors?.procedure_cid">
              {{ errors.procedure_cid }}
            </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Caráter de atendimento" :state="errors.procedure_attendance_character ? false : null">
              <multiselect
                id="attendance_character"
                v-model="procedure.attendance_character"
                :options="attendanceCharacterOptions"
                :option-height="40"
                :showLabels="false"
                :searchable="true"
                track-by="id"
                label="label"
                placeholder="Selecionar"
                class="with-border"
                ref="procedure_attendance_character"
                >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>

                <template slot="noOptions"> Nenhuma opção </template>

                <template slot="noResult"> Nenhum resultado </template>
              </multiselect>
              <b-form-invalid-feedback v-if="errors?.procedure_attendance_character">
                {{ errors.procedure_attendance_character }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Nº da autorização">
              <b-form-input
                v-model="procedure.authorization_number"
                placeholder="Descrever"
                autocomplete="off"
                :state="errors.procedure_authorization_number ? false : null"
                ref="procedure_authorization_number"
              />
              <b-form-invalid-feedback v-if="errors?.procedure_authorization_number">
                {{ errors.procedure_authorization_number }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
  
      <div class="wrapper-button text-center mt-3">
        <b-button variant="primary" size="lg" @click="saveProcedure" v-if="readonly">
          Salvar procedimento
        </b-button>
      </div>
    </b-modal>
  </template>
  
  <script>
  import ChevronDown from '@/assets/icons/chevron-down.svg'

  export default {
    name: 'ProcedureBpaModal',
    components: {
      Close: () => import('@/assets/icons/close.svg'),
      ChevronDown: () => import('@/assets/icons/chevron-down-select.svg'),
      'v-chevron-down': ChevronDown,
      ItemHealthPlanSimpleSelector: () => import('@items/components/ItemHealthPlanSimpleSelector'),
    },
    data() {
      return {
        attendanceCharacterOptions:[],
        cid10: [],
        errors: {}
      }
    },
    props: {
      type: String,
      procedure: {
        type: Object,
        default: () => ({}),
      },
      readonly: Boolean,
      clinicHealthPlanId: String,
      requestFields: []
    },
    mounted (){
      this.getCharacterService();      
    },
    methods: {
      getCharacterService() {
        if (this.attendanceCharacterOptions.length === 0) {
          this.api.getCharacterService().then(async res => {
            this.attendanceCharacterOptions = res.data.map(type => {
              return {
                label: `${type?.code} - ${type.description}`,
                code: type.code,
                id: type.id
              }
            })
          })
        }
      },
      setGuideItem(item) {
        this.procedure.item = item;
        this.procedure.code = item.code;
        this.procedure.item_id = item.id;
        this.procedure.name = item.name;
        this.procedure.quantity = 1;
        this.procedure.unitary_value = item.health_plan_props.value;
      },
      onShow() {
        if (this.procedure.cid) {
          this.procedure.cid = {
            ...this.procedure.cid,
            label: this.procedure.cid.code + ' - ' + this.procedure.cid.description,
            value: this.procedure.cid.id,
            id: this.procedure.cid.id,
          };
        }
      },
      closeModal() {
        this.$bvModal.hide('procedure-bpa-modal')
      },
      saveProcedure() {
        if (this.isValidForm()) {
          this.$toast.warning('Preencha todos os campos obrigatórios.')
          return
        }
        this.$emit('update-procedures', { 
          ...this.procedure,  
          cid_id: this.procedure.cid.id,
          character_service_id: this.procedure.attendance_character.id
        });
        this.closeModal();
      },
      async searchCid10(query) {
        if (query.length > 2) {
          this.cid10 = []
          try {
            const response = await this.api.searchCid10({ term: query })
            this.cid10 = response.data.map(cid10 => {
              return {
                label: `${cid10?.code} - ${cid10.description}`,
                id: cid10.id
              }
            })
          } catch (error) {
            this.$toast.error(error.message)
          }
        }
      },
      selectCid1(cid) {
        this.procedure.cid_id = cid.id;
      },
      isValidForm() {
        const errors = {};

        if(!this.procedure.item_id){
          errors.procedure_item = "Campo Obrigatório";
        }

        if(!this.procedure.quantity){
          errors.procedure_quantity = "Campo Obrigatório";
        }

        if(!this.procedure.attendance_date){
          errors.procedure_attendance_date = "Campo Obrigatório";          
        }

        if(!this.procedure?.cid){
          errors.procedure_cid = "Campo Obrigatório";          
        }

        if(!this.procedure?.attendance_character){
          errors.procedure_attendance_character = "Campo Obrigatório";          
        }
        
        Object.keys(this.requestFields).map(item => {
          if(this.$refs[item] && (!this.$refs[item].value || this.$refs[item].value.length === 0) && this.requestFields[item]){
            errors[item] = "Campo obrigatório";
          }
        });

        this.errors = errors;

        return Object.keys(errors).length;
      }
    },
    watch: {
      'procedure.quantity'() {
        this.procedure.total_value = this.procedure.unitary_value * this.procedure.quantity
      },
      'procedure.unitary_value'() {
        this.procedure.total_value = this.procedure.unitary_value * this.procedure.quantity
      },
    }
  };
  </script>
  <style lang="scss">
    #procedure-bpa-modal {
      .form-title {
        color: var(--neutral-600);
        font-size: 18px;
        font-weight: bolder;
        line-height: 28px;
      }
      .modal-header {
        .modal-title {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }

        .icon-close {
          width: 25px;

          svg {
            fill: var(--neutral-500);
          }
        }
      }
      
      .col-form-label {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          color: #0a184c;
          margin: 0;
          margin-bottom: 0px;
      }

      .invalid-feedback{
        display: block;
      }
  }
  </style>