<template>
  <b-row class="mt-3">
    <b-col cols="12">
      <p class="form-title mb-3">Procedimento realizado</p>
    </b-col>
    
    <b-col cols="12">
      <b-table-simple fixed class="table" responsive borderless>
        <thead>
          <tr>
            <th v-if="type === 'bpa'">Data</th>
            <th>Cód. proced.</th>
            <th>Descrição</th>
            <th>Qtde.</th>
            <th v-if="type === 'apac'">Principal</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in procedures" :key="index">
            <td v-if="type === 'bpa'">{{ formatDate(item.attendance_date) }}</td>
            <td>{{ item.code }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.quantity }}</td>
            <td v-if="type === 'apac'">
              <input type="checkbox" v-model="item.is_principal" :disabled="true">
            </td>
            <td class="text-right d-flex">
              <Edit v-if="readonly" class="edit" @click="editProcedure(item, index)" />
              <Delete v-if="readonly" class="delete" @click="confirmDelete(index)" />
            </td>
          </tr>
        </tbody>
      </b-table-simple>            
    </b-col>

    <ProcedureBpaModal
      v-if="type === 'bpa'"
      :procedure="selectedProcedure"
      :readonly="readonly"
      @update-procedures="updateProcedure"
      :clinicHealthPlanId="clinicHealthPlanId"
      :requestFields="requestFields"
      :isValidCPF="isValidCPF"
      :isValidCNPJ="isValidCNPJ"
    />

    <ProcedureApacModal
      v-if="type === 'apac'"
      :procedure="selectedProcedure"
      :readonly="readonly"
      @update-procedures="updateProcedure"
      :clinicHealthPlanId="clinicHealthPlanId"
      :requestFields="requestFields"
      :isValidCPF="isValidCPF"
      :isValidCNPJ="isValidCNPJ"
    />

    <b-col cols="12">
      <div class="col-12 text-center">
        <hr id="hr" />
        <b-button variant="link" :disabled="!readonly" @click="openModalToAdd">
          Adicionar procedimento
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import ProcedureBpaModal from './ProcedureBpaModal.vue';
import ProcedureApacModal from './ProcedureApacModal.vue';
import moment from 'moment';

export default {
  name: 'ProceduresPerformed',
  components: {
    Edit: () => import('@/assets/icons/edit.svg'),
    Delete: () => import('@/assets/icons/delete.svg'),
    ProcedureBpaModal,
    ProcedureApacModal
  },
  props: {
    type: String,
    checkedItems: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clinicHealthPlanId: String,
    requestFields: [],
    isValidCPF: {
      type: Function,
      default: () => {}
    },
    isValidCNPJ: {
      type: Function,
      default: () => {}
    },
  },
  data() {
    return {
      procedures: [],
      selectedProcedure: {},
    };
  },
  created() {
    this.initializeProcedures();
  },
  watch: {
    checkedItems: {
      handler(newVal) {
          if (newVal) {
              this.checkedItems = newVal;
          }
        },
        immediate: true,
        deep: true
      },
  },
  methods: {
    formatDate(date) {
      return date && moment(date).isValid() ? moment(date).format('DD/MM/YYYY') : '';
    },
    initializeProcedures() {
      this.procedures = this.checkedItems;
      this.$emit('update-procedures', this.checkedItems);
    },
    uncheckPrincipalProcedure(){
      this.procedures.map(item => {
          item.is_principal = false;
      });
    },
    updateProcedure(updatedProcedure) {
      if(updatedProcedure.is_principal){
        this.uncheckPrincipalProcedure();
      }
      
      if (updatedProcedure.index !== undefined) {
        this.$set(this.procedures, updatedProcedure.index, updatedProcedure);
      } else {
        this.procedures.push(updatedProcedure);
      }

      this.$bvModal.hide('procedure-' + this.type + '-modal');
      this.$emit('update-procedures', this.procedures);
    },
    openModalToAdd() {
      this.selectedProcedure = {
        is_principal: this.procedures.length === 0,
        quantity: 1
      };
      this.$bvModal.show('procedure-' + this.type + '-modal');
    },
    editProcedure(item, index) {
      item.index  = index;
      this.selectedProcedure = { ...item };
      this.$bvModal.show('procedure-' + this.type + '-modal');
    },
    confirmDelete(index) {
      if(this.procedures[index].appointment_item_id){
        this.$toast.error("Esse item está vinculado ao atendimento, e não pode ser removido.");
        return;
      }

      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente remover este procedimento?</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
      }).then(res => {
        if (res.isConfirmed) {
          this.procedures.splice(index, 1);
          this.$emit('update-procedures', this.procedures);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';

.edit, .delete {
  width: 24px !important;
  height: 24px !important;
  cursor: pointer;
}

.edit {
  stroke: var(--blue-500);
}

.delete {
  stroke: var(--red-500);
}
</style>
